import { ApiResult } from '../models/ApiResult';
import {
  DroneData,
  DroneProfile,
  DroneProfileBackend,
  DroneProfilePatch,
  FullDroneProfileInput,
  OrganizationDroneProfiles,
} from '../models/DroneProfile/DroneProfile.model';
import { apiClient } from './ApiService';
import { serializeDroneProfileFromBackend } from './serializers/DroneProfileSerializer';

const createDroneProfile = (droneProfile: FullDroneProfileInput): Promise<ApiResult<DroneProfile>> => {
  return apiClient
    .post<DroneProfileBackend>('/drone_commander/drone-profiles/', droneProfile)
    .then((backendProfile) => {
      return {
        success: true,
        data: serializeDroneProfileFromBackend(backendProfile),
      };
    });
};

const getOrganizationDroneProfiles = async (orgId: number): Promise<OrganizationDroneProfiles> => {
  try {
    return await apiClient.get<OrganizationDroneProfiles>(
      `/drone_commander/organizations/${orgId}/drone-profiles/`,
      {},
      false,
    );
  } catch (error) {
    console.error(`Error fetching details for organization ${orgId}:`, error);
    throw error;
  }
};

const getDroneProfile = (droneProfileEid: string): Promise<ApiResult<{ drone_profile: DroneProfile }>> => {
  return apiClient
    .get<{
      status: number;
      message: string;
      drone_profile: DroneProfile;
    }>(`/drone_commander/drone-profile/${droneProfileEid}/`)
    .then((response) => {
      if (response && response.drone_profile) {
        return { success: true, data: response };
      } else {
        throw new Error('Drone profile not found');
      }
    });
};

const updateDroneProfile = (droneProfile: DroneProfilePatch): Promise<ApiResult<DroneProfile>> => {
  return apiClient
    .patch<DroneProfileBackend>(`/drone_commander/drone-profiles/${droneProfile.id}/`, droneProfile)
    .then((profile) => {
      if (profile) {
        return { success: true, data: serializeDroneProfileFromBackend(profile) };
      } else {
        throw new Error('Drone profile not found');
      }
    });
};

const deleteDroneProfile = (droneIds: string[]): Promise<ApiResult<Record<number, never>>> => {
  return apiClient
    .delete<DroneProfileBackend[]>('/drone_commander/drone-profiles/bulk-remove-droneprofiles/', { ids: droneIds })
    .then((response) => {
      if (response) {
        return { success: true, data: {} };
      } else {
        throw new Error('Drone profile not found');
      }
    });
};

const getDroneData = async (droneId: string): Promise<ApiResult<DroneData>> => {
  try {
    const response = await apiClient.get<DroneData>(`/drone_commander/drone/${droneId}/`);
    return {
      success: true,
      data: response,
    };
  } catch (error) {
    throw error;
  }
};

export const DroneProfileService = {
  createDroneProfile,
  getOrganizationDroneProfiles,
  deleteDroneProfile,
  updateDroneProfile,
  getDroneData,
  getDroneProfile,
};
