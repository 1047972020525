import React from 'react';
import { TextInputField } from './TextInputField';
import { CreateAccountFormFields } from '../types/CreateAccountFormFields';
import getAccountFormSchema from '../schema/getAccountFormSchema';
import { Button, useTheme, Box, Typography } from '@mui/joy';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import VideoLayout from '../components/layout/VideoLayout';

interface CreateAccountFormProps {
  disableEmail: boolean;
  buttonText: string;
  email?: string;
  formData: CreateAccountFormFields;
  setFormData: (formData: CreateAccountFormFields) => void;
  errors: Record<string, string>;
  setErrors: (errors: Record<string, string>) => void;
  onSubmit: () => void;
}

export const CreateAccountForm: React.FC<CreateAccountFormProps> = ({
  disableEmail,
  buttonText,
  formData,
  setFormData,
  errors,
  setErrors,
  onSubmit,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const validateForm = async (): Promise<boolean> => {
    const schema = getAccountFormSchema(t);
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors) {
      if (validationErrors instanceof Yup.ValidationError) {
        const formErrors: Record<string, string> = {};
        validationErrors.inner.forEach((error) => {
          if (error.path) {
            formErrors[error.path] = error.message;
          }
        });
        setErrors(formErrors);
      }
      return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      onSubmit();
    }
  };

  return (
    <VideoLayout>
      <Box sx={{ maxWidth: '28rem' }}>
        <Typography
          level="h2"
          sx={{
            fontSize: '2.5rem',
            lineHeight: '3rem',
            fontWeight: 600,
            marginBottom: '1.5rem',
            fontFamily: theme.fontFamily.display,
          }}
        >
          {t('signup_page.create_sifly_account')}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextInputField
            label={t('signup_page.email_address')}
            name="email"
            value={formData.email}
            placeholder={t('signup_page.email_address')}
            onChange={handleChange}
            error={errors.email}
            disabled={disableEmail}
            sx={{ paddingX: '1rem', paddingY: '0.75rem' }}
          />
          <Box sx={{ display: { lg: 'flex' }, justifyContent: { lg: 'space-between' } }}>
            <TextInputField
              label={t('signup_page.first_name')}
              name="firstName"
              placeholder={t('signup_page.first_name')}
              value={formData.firstName}
              onChange={handleChange}
              error={errors.firstName}
              sx={{ paddingX: '1rem', paddingY: '0.75rem' }}
            />
            <TextInputField
              label={t('signup_page.last_name')}
              name="lastName"
              placeholder={t('signup_page.last_name')}
              value={formData.lastName}
              onChange={handleChange}
              error={errors.lastName}
              sx={{ paddingX: '1rem', paddingY: '0.75rem' }}
            />
          </Box>
          <TextInputField
            label={t('signup_page.call_sign')}
            name="callSign"
            placeholder={t('signup_page.call_sign')}
            value={formData.callSign}
            onChange={handleChange}
            error={errors.callSign}
            sx={{ paddingX: '1rem', paddingY: '0.75rem' }}
          />
          <TextInputField
            label={t('signup_page.password')}
            name="password"
            placeholder={t('signup_page.password')}
            type="password"
            value={formData.password}
            onChange={handleChange}
            error={errors.password}
            sx={{ paddingX: '1rem', paddingY: '0.75rem' }}
          />
          <TextInputField
            label={t('signup_page.confirm_password')}
            name="confirmPassword"
            placeholder={t('signup_page.confirm_password')}
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
            error={errors.confirmPassword}
            sx={{ paddingX: '1rem', paddingY: '0.75rem' }}
          />
          <Button
            type="submit"
            color="neutral"
            sx={{
              width: '100%',
              paddingY: '0.75rem',
              paddingX: '1.5rem',
              marginTop: '1rem',
              fontWeight: 400,
              height: '3rem',
            }}
          >
            {buttonText}
          </Button>
        </form>

        <Box sx={{ marginTop: '1rem', textAlign: 'center' }}>
          <Typography sx={{ display: 'flex', justifyContent: 'center', fontWeight: 400, fontSize: '1rem' }}>
            <Typography style={{ marginRight: '0.5rem' }}>{t('signup_page.already_have_account')}</Typography>
            <Link to={'/'} className="underline">
              {t('login_page.sign_in')}
            </Link>
          </Typography>
        </Box>
      </Box>
    </VideoLayout>
  );
};
