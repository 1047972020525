import {
  MdOutlineBattery1Bar,
  MdOutlineBattery2Bar,
  MdOutlineBattery4Bar,
  MdOutlineBattery5Bar,
  MdBatteryFull,
} from 'react-icons/md';

export const getBatteryIcon = (batteryPercentage: number) => {
  if (batteryPercentage < 20) {
    return <MdOutlineBattery1Bar size={18} color="#f44336" />;
  } else if (batteryPercentage >= 20 && batteryPercentage < 45) {
    return <MdOutlineBattery2Bar size={18} color="#1F7A1F" />;
  } else if (batteryPercentage >= 45 && batteryPercentage <= 55) {
    return <MdOutlineBattery4Bar size={18} color="#1F7A1F" />;
  } else if (batteryPercentage > 55 && batteryPercentage < 95) {
    return <MdOutlineBattery5Bar size={18} color="#1F7A1F" />;
  } else if (batteryPercentage >= 95) {
    return <MdBatteryFull size={18} color="#1F7A1F" />;
  }
  return null;
};
