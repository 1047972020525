export interface ErrorDetails {
  [key: string]: string[]; // This maps field names to an array of error messages
}

export interface ErrorResponse {
  status: number;
  message: string;
  details?: ErrorDetails; // The 'details' field is optional
}

export interface DroneError extends ErrorResponse {
  droneErrorCode: number;
}

export class ValidationException extends Error {
  public status: number;
  public details?: ErrorDetails;

  constructor(message: string, status: number, details?: ErrorDetails) {
    super(message);
    this.name = 'ValidationException';
    this.status = status;
    this.details = details;
  }

  static fromErrorResponse(error: ErrorResponse): ValidationException {
    return new ValidationException(error.message, error.status, error.details);
  }
}

export class ServerException extends Error {
  public status: number;
  public response: ErrorResponse;

  constructor(message: string, status: number, response: ErrorResponse) {
    super(message);
    this.name = 'ServerException';
    this.status = status;
    this.response = response;
  }

  static fromErrorResponse(error: ErrorResponse): ServerException {
    return new ServerException(error.message, error.status, error);
  }
}

export class InvalidSessionException extends Error {
  public status: number;
  public response: ErrorResponse;
  constructor(message: string, status: number, response: ErrorResponse) {
    super(message);
    this.name = 'InvalidSessionException';
    this.status = status;
    this.response = response;
  }
  static fromErrorResponse(error: ErrorResponse): ServerException {
    return new InvalidSessionException(error.message, error.status, error);
  }
}

export class ForbiddenException extends Error {
  public status: number;
  public response: ErrorResponse;
  constructor(message: string, status: number, response: ErrorResponse) {
    super(message);
    this.name = 'ForbiddenException';
    this.status = status;
    this.response = response;
  }
  static fromErrorResponse(error: ErrorResponse): ServerException {
    return new ForbiddenException(error.message, error.status, error);
  }
}

export class DroneException extends Error {
  public status: number;
  public droneErrorCode: number;
  public details?: ErrorDetails;

  constructor(message: string, status: number, droneErrorCode: number, details?: ErrorDetails) {
    super(message);
    this.name = 'DroneException';
    this.status = status;
    this.droneErrorCode = droneErrorCode;
    this.details = details;
  }

  static fromErrorResponse(error: DroneError): DroneException {
    return new DroneException(error.message, error.status, error.droneErrorCode, error.details);
  }
}

export type SiflyError =
  | ValidationException
  | ServerException
  | InvalidSessionException
  | ForbiddenException
  | DroneException;
