import { apiClient } from './ApiService';
import { Campaign } from '../types/Campaign';

const getCampaigns = async (): Promise<Campaign[]> => {
  try {
    return await apiClient.get<Campaign[]>('/drone_commander/campaigns/');
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

const updateCampaignStatus = async (campaignId: string, newStatus: number): Promise<Campaign> => {
  try {
    return await apiClient.patch<Campaign>(`/drone_commander/campaigns/${campaignId}/`, {
      status: newStatus,
    });
  } catch (error) {
    console.error(`Error updating status for campaign ${campaignId}:`, error);
    throw error;
  }
};

const createCampaign = async (newCampaignData: {
  name: string;
  description: string;
  status: number;
  organization: number | null;
}): Promise<Campaign> => {
  try {
    return await apiClient.post<Campaign>('/drone_commander/campaigns/', newCampaignData);
  } catch (error) {
    console.error('Error creating new campaign: ', error);
    throw error;
  }
};

export const CampaignService = {
  getCampaigns,
  updateCampaignStatus,
  createCampaign,
};
