import { FC } from 'react';
import { useRoutes } from 'react-router-dom';

import Login from '../pages/Login';
import { Signup } from '../pages/Signup';
import VerifyEmail from '../pages/VerifyEmail';
import Locations from '../pages/Locations';
import DroneDetails from '../pages/DroneDetails';
import DroneMission from '../pages/DroneMission';
import NotFound from '../pages/NotFound';
import RoleAssignment from '../pages/RoleAssignment';
import SendOrgInvitation from '../pages/SendOrgInvitation';
import AcceptOrgInvitation from '../pages/AcceptOrgInvitation';
import UserList from '../pages/UserList';
import ProtectedRoute from '../components/ProtectedRoute';
import Dashboard from '../pages/Dashboard';
import UsersPermissions from '../pages/UserPermissions';
import Logout from '../pages/Logout';
import InvitePage from '../pages/InvitePage';
import { CreateAccount } from '../pages/CreateAccount';
import OrganizationList from '../pages/OrganizationList';
import DroneModelList from '../pages/DroneModels/DroneModelList';
import DroneDetailsPage from '../pages/DroneModels/DroneDetailsPage';
import CreateDroneModelPage from '../pages/DroneModels/CreateDroneModelPage';
import { AccountPreferences } from '../pages/AccountPreferences/AccountPreferences';
import { CreateOrganization } from '../pages/CreateOrganization';
import { ResetPassword } from '../pages/ResetPassword/ResetPassword';
import DroneProfileList from '../pages/DroneProfiles/DroneProfileList';
import DroneOverview from '../pages/DroneOverview';
import RegisterDrone from '../pages/RegisterDrone';
import FactoryResetDrone from '../pages/FactoryResetDrone';
import Campaign from '../pages/Campaign';

const routes = [
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/verify-email/:token',
    element: <VerifyEmail />,
  },
  {
    path: '/accept-invitation/:token',
    element: <AcceptOrgInvitation />,
  },
  {
    path: '/invite-page',
    element: <InvitePage />,
  },
  {
    path: '/create-account',
    element: <CreateAccount />,
  },
  {
    path: '/organization-list',
    element: <OrganizationList />,
  },
  {
    path: '/',
    element: <ProtectedRoute />,
    children: [
      {
        path: '/locations',
        element: <Locations />,
      },

      {
        path: '/drone-details',
        element: <DroneDetails />,
      },
      {
        path: '/drone-mission',
        element: <DroneMission />,
      },

      {
        path: '/users',
        element: <UserList />,
      },
      {
        path: '/assign_role',
        element: <RoleAssignment />,
      },
      {
        path: '/send-invitation',
        element: <SendOrgInvitation />,
      },
      {
        path: '/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/logout',
        element: <Logout />,
      },
      {
        path: '/account-preferences',
        element: <AccountPreferences />,
      },
      {
        path: '/user-permissions',
        element: <UsersPermissions />,
      },
      {
        path: '/drone-models',
        element: <DroneModelList />,
      },
      {
        path: '/drone-model/:id',
        element: <DroneDetailsPage />,
      },
      {
        path: '/drone-models/new',
        element: <CreateDroneModelPage />,
      },
      {
        path: '/create-organization',
        element: <CreateOrganization />,
      },
      {
        path: '/drone-profiles',
        element: <DroneProfileList />,
      },
      {
        path: '/drone-overview/:id',
        element: <DroneOverview />,
      },
      {
        path: '/register-drone/:id',
        element: <RegisterDrone />,
      },
      {
        path: '/factory-reset-instructions/:id',
        element: <FactoryResetDrone />,
      },
      {
        path: '/campaigns',
        element: <Campaign />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

const AppRoutes: FC = () => {
  const routing = useRoutes(routes);
  return routing;
};

export default AppRoutes;
