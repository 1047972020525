import { Box } from '@mui/joy';
import { ReactNode } from 'react';
import { SiflyLogo } from '../../general-utilities/SiflyLogo';

interface SimpleHeaderLayoutProps {
  children: ReactNode;
  controlComponent?: ReactNode;
}

export const SimpleHeaderLayout: React.FC<SimpleHeaderLayoutProps> = ({ children, controlComponent }) => {
  const logo = SiflyLogo();

  return (
    <Box className="min-h-screen flex flex-col">
      <Box className="border-b border-opacity-30 flex justify-between">
        {logo && <img className="h-5 w-28 font-medium m-5" src={logo} alt="Logo" />}
        {controlComponent}
      </Box>

      {children}
    </Box>
  );
};
