import { useState, useEffect } from 'react';
import { UpdatePasswordService } from '../services/UpdatePasswordService';
import SessionStore from '../stores/SessionStore';
import { toast } from 'react-toastify';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { CreateAccountForm } from '../components/CreateAccountForm';
import { CreateAccountFormFields } from '../types/CreateAccountFormFields';
import { useTranslation } from 'react-i18next';

export const CreateAccount = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [formData, setFormData] = useState<CreateAccountFormFields>({
    firstName: '',
    lastName: '',
    callSign: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    const email = searchParams.get('email') || '';
    setEmail(email);

    setFormData((prevValues) => ({
      ...prevValues,
      email,
    }));

    sessionStorage.setItem('token', searchParams.get('token') || '');
    sessionStorage.setItem('org', searchParams.get('org') || '');
  }, [location]);

  const createAccount = async () => {
    const token = sessionStorage.getItem('token') || '';
    const org = sessionStorage.getItem('org') || '';

    try {
      await UpdatePasswordService.updatePassword(formData, token);
      await SessionStore.login({ email: formData.email, password: formData.password });

      navigate(`/organization-list?token=${token}&org=${org}`);
    } catch (error) {
      toast.error(t('signup_page.errors.form_submission'));
    }
  };

  return (
    <CreateAccountForm
      disableEmail
      buttonText={t('signup_page.create_account')}
      email={email}
      formData={formData}
      setFormData={setFormData}
      errors={errors}
      setErrors={setErrors}
      onSubmit={createAccount}
    />
  );
};
