import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { yupResolver } from '@hookform/resolvers/yup';
import DynamicTable, { TableColumn } from '../../components/DynamicTable';
import TableHeader from '../../components/TableHeader';
import GeneralModal from '../../components/GeneralModal';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import { DroneProfile } from '../../models/DroneProfile/DroneProfile.model';
import { DroneModel } from '../../models/DroneModel/DroneModel.model';
import ResponsiveLayout from '../../components/layout/ResponsiveLayout';
import { DroneModelService } from '../../services/DroneModelService';
import { DroneProfileService } from '../../services/DroneProfileService';
import { FormControl, FormLabel, Grid, Select, Typography } from '@mui/joy';
import Option from '@mui/joy/Option';
import { organizationStore } from '../../stores/OrganizationStore';
import { TextInputField } from '../../components/TextInputField';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import DroneProfileSchema from '../../schema/droneProfileSchema';

interface DroneProfileFormInput {
  name: string;
  eid: string;
  drone_model: string;
}

const DroneProfileList = observer(() => {
  const { t } = useTranslation();
  const { selectedOrg } = organizationStore;
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState<DroneProfile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<'add' | 'update' | 'delete'>('add');
  const [droneProfiles, setDroneProfiles] = useState<DroneProfile[]>([]);
  const [droneModels, setDroneModels] = useState<DroneModel[]>([]);
  const [selectedModel, setSelectedModel] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DroneProfileFormInput>({
    resolver: yupResolver(DroneProfileSchema(t)),
    defaultValues: {
      name: '',
      eid: '',
      drone_model: '',
    },
  });

  const fetchDroneProfiles = useCallback(async () => {
    if (selectedOrg !== null) {
      try {
        const response = await DroneProfileService.getOrganizationDroneProfiles(selectedOrg);
        setDroneProfiles(response.data.drone_profiles);
        setSelectedRows([]);
      } catch {
        toast.error(t('drone_profiles.errors.failed_to_fetch_drone_profiles'));
      }
    }
  }, [selectedOrg]);

  const fetchDroneModels = async () => {
    try {
      const data = await DroneModelService.getDroneModels();
      setDroneModels(data);
    } catch {
      toast.error(t('drone_profiles.errors.failed_to_fetch_drone_models'));
    }
  };

  useEffect(() => {
    fetchDroneProfiles();
    fetchDroneModels();
  }, [fetchDroneProfiles, selectedOrg]);

  const columns: TableColumn<DroneProfile, keyof DroneProfile>[] = [
    { header: 'Name', key: 'name' },
    { header: 'EID', key: 'eid' },
    { header: 'Drone Models', key: 'drone_model_name' },
  ];

  const handleSelectionChange = (selected: DroneProfile[]) => {
    setSelectedRows(selected);
  };

  const handleOpenModal = (type: 'add' | 'update' | 'delete') => {
    if (type === 'add') {
      reset({ name: '', eid: '', drone_model: '' });
      setSelectedModel(null);
    } else if (type === 'update' && selectedRows.length === 1) {
      const selectedProfile = selectedRows[0];
      reset({
        name: selectedProfile.name,
        eid: selectedProfile.eid,
        drone_model: selectedProfile.drone_model,
      });
      setSelectedModel(selectedProfile.drone_model);
    }
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit = async (data: DroneProfileFormInput) => {
    try {
      if (modalType === 'update' && selectedRows.length === 1) {
        const payload = {
          id: selectedRows[0].id,
          ...data,
        };
        await DroneProfileService.updateDroneProfile(payload);
        toast.success(t('drone_profiles.messages.drone_profile_updated'));
      } else if (modalType === 'add') {
        await DroneProfileService.createDroneProfile({
          ...data,
          organization: selectedOrg!,
        });
        toast.success(t('drone_profiles.messages.drone_profile_created'));
      }

      fetchDroneProfiles();
      setIsModalOpen(false);
    } catch {
      toast.error(t('drone_profiles.errors.failed_to_save'));
    }
  };

  const handleDeleteProfiles = async () => {
    const idsToDelete = selectedRows.map((row) => row.id);
    try {
      await DroneProfileService.deleteDroneProfile(idsToDelete);
      toast.success(t('drone_profiles.messages.drone_profile_deleted'));
      fetchDroneProfiles();
      setIsModalOpen(false);
    } catch {
      toast.error(t('drone_profiles.errors.failed_to_delete'));
    }
  };

  const modalContent =
    modalType === 'add' || modalType === 'update' ? (
      <form id="drone-profile-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextInputField
                  label="Name"
                  placeholder="Drone Profile Name"
                  required
                  {...field}
                  error={errors.name?.message}
                />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <Controller
              name="eid"
              control={control}
              render={({ field }) => (
                <TextInputField label="EID" placeholder="EID" required {...field} error={errors.eid?.message} />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <FormControl required>
              <FormLabel>Select Drone Model</FormLabel>
              <Controller
                name="drone_model"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    value={selectedModel || ''}
                    onChange={(event, newValue) => {
                      setSelectedModel(newValue);
                      field.onChange(newValue);
                    }}
                    sx={{ minWidth: '12rem' }}
                    placeholder="Select Drone Model"
                    id="drone-model-select"
                  >
                    {droneModels.map((model) => (
                      <Option key={model.id} value={model.id}>
                        {model.name}
                      </Option>
                    ))}
                  </Select>
                )}
              />
              {errors.drone_model && <Typography color="danger">{errors.drone_model.message}</Typography>}
            </FormControl>
          </Grid>
        </Grid>
      </form>
    ) : (
      <Typography>
        {t('drone_profiles.confirm_delete_msg')}
        {selectedRows.length} {selectedRows.length > 1 ? 'profiles' : 'profile'}?
      </Typography>
    );

  const modalActions =
    modalType === 'delete' ? (
      <>
        <Button variant="plain" color="neutral" onClick={handleCloseModal}>
          {t('general_button_labels.cancel')}
        </Button>
        <Button variant="solid" color="danger" onClick={handleDeleteProfiles}>
          {t('general_button_labels.delete')}
        </Button>
      </>
    ) : (
      <>
        <Button variant="plain" color="neutral" onClick={handleCloseModal}>
          {t('general_button_labels.cancel')}
        </Button>
        <Button type="submit" form="drone-profile-form" variant="solid" color="neutral">
          {t('general_button_labels.save')}
        </Button>
      </>
    );

  return (
    <ResponsiveLayout>
      <TableHeader
        title={
          selectedRows.length > 0
            ? `${selectedRows.length} ${selectedRows.length > 1 ? 'Drone Profiles' : 'Drone Profile'} Selected`
            : 'Drone Profiles'
        }
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, ml: 2 }}>
          <Input
            placeholder="Search"
            startDecorator={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              '--Input-minHeight': '2.5rem',
              maxWidth: '25rem',
              width: '100%',
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', gap: 1 }}>
          {selectedRows.length === 0 ? (
            <Button color="neutral" onClick={() => handleOpenModal('add')}>
              {t('drone_profiles.add_button')}
            </Button>
          ) : selectedRows.length === 1 ? (
            <>
              <Button color="neutral" onClick={() => handleOpenModal('update')}>
                {t('drone_profiles.update_button')}
              </Button>
              <Button color="danger" onClick={() => handleOpenModal('delete')}>
                {t('general_button_labels.delete')}
              </Button>
            </>
          ) : (
            <Button color="danger" onClick={() => handleOpenModal('delete')}>
              {t('general_button_labels.delete')}
            </Button>
          )}
        </Box>
      </TableHeader>

      <DynamicTable
        columns={columns}
        data={droneProfiles}
        selectedRows={selectedRows}
        onSelectionChange={handleSelectionChange}
      />

      <GeneralModal
        open={isModalOpen}
        title={modalType === 'delete' ? t('general_button_labels.delete') : t('drone_profiles.title')}
        content={modalContent}
        actions={modalActions}
        onClose={handleCloseModal}
      />
    </ResponsiveLayout>
  );
});

export default DroneProfileList;
