import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/joy';
import { InvitationService } from '../services/InvitationService';
import { useTranslation } from 'react-i18next';

export default function InvitePage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [hasPassword, setHasPassword] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const { t } = useTranslation();

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      email: params.get('email') || '',
      token: params.get('token') || '',
      org: params.get('org') || '',
    };
  };

  const { email, token, org } = getQueryParams();

  useEffect(() => {
    const validateInvitation = async () => {
      try {
        const response = await InvitationService.validateInvitation(token, email);

        if (response.status === 200) {
          setIsValid(true);
          const { has_password, is_verified } = response;
          setIsVerified(is_verified);
          setHasPassword(has_password);
        } else {
          setMessage(response.message);
          setIsValid(false);
        }
      } catch {
        setMessage(t('invite_page.errors.link_expired'));
        setIsValid(false);
      }
    };

    validateInvitation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, token]);

  const navigationLink = () => {
    if (isVerified && hasPassword) {
      return `/?token=${token}&org=${org}&email=${email}`;
    } else {
      return `/create-account?email=${email}&token=${token}&org=${org}`;
    }
  };

  const handleClick = () => {
    const link = navigationLink();
    navigate(link);
  };

  return (
    <div
      className="min-h-screen overflow-hidden flex flex-col sm:flex-row"
      style={{ background: theme.palette.neutral[100] }}
    >
      <div className="relative sm:w-3/5 flex-shrink-0">
        <img
          className="absolute top-0 left-0 h-5 w-28 font-medium m-5 z-10"
          src="images/logos/logo.png"
          alt="Sifly Logo"
        />

        <video autoPlay muted loop className="sm:min-h-full max-w-full object-fill sm:object-none">
          <source src="/videos/siflylogin.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="flex items-center justify-center sm:min-h-[75vh] p-6 sm:w-2/5">
        <div className="max-w-[29rem]">
          <h1
            className="text-4xl leading-[3rem] font-semibold mb-4"
            style={{ color: theme.palette.neutral[800], fontFamily: theme.fontFamily.display }}
          >
            {isValid ? t('invite_page.invite_message') : message}
          </h1>
          <p className="text-lg" style={{ color: theme.palette.neutral[600] }}>
            {isValid ? (
              <>
                {t('invite_page.invitation_sent')} <strong>{email}</strong>
              </>
            ) : (
              t('invite_page.errors.contact_admin')
            )}
          </p>

          {isValid && (
            <button
              type="submit"
              onClick={handleClick}
              className="cursor-pointer w-full py-2 px-6 mt-6 text-base leading-4 font-normal rounded bg-vibrantYellow-500 hover:bg-vibrantYellow-300 h-12"
              style={{ color: theme.palette.neutral[800] }}
            >
              {t('invite_page.continue')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
