export interface DroneProfile {
  id: string;
  name: string;
  eid: string;
  drone_model: string;
  drone_model_name?: string;
  createdAt: Date;
  updatedAt: Date;
}

export type DroneProfilePatch = { id: string } & Partial<Omit<DroneProfile, 'id'>>;
export type DroneProfileCreate = Omit<DroneProfile, 'id' | 'createdAt' | 'updatedAt'>;

export interface DroneProfileBackend {
  id: string;
  name: string;
  eid: string;
  drone_model: string;
  drone_model_name: string;
  created_at: string;
  updated_at: string;
}

export interface FullDroneProfileInput {
  name: string;
  eid: string;
  drone_model: string;
  organization: number;
}

export enum DroneState {
  BOOTING = 'booting',
  IDLE = 'idle',
  PERFORMING_FACTORY_RESET = 'performing-factory-reset',
  AWAITING_PROVISIONING = 'awaiting-provisioning',
  UPDATING = 'updating',
  ON_MISSION = 'on-mission',
  HARDWARE_ERROR = 'hardware-error',
  NETWORK_ERROR = 'network-error',
  CONFIGURATION_ERROR = 'configuration-error',
  LOW_BATTERY = 'low-battery',
  SHUTDOWN = 'shutdown',
}

export enum ProvisioningState {
  UNPROVISIONED = 'unprovisioned',
  PROVISIONING = 'provisioning',
  PROVISIONED = 'provisioned',
}

export interface DroneData {
  data: {
    eid: string;
    name: string;
    state: DroneState;
    battery: {
      percentage: number;
      type: string;
    };
    provisioning_state: ProvisioningState;
    location: {
      lat: string;
      lon: string;
    };
  };
}

export type OrganizationDroneProfiles = {
  data: {
    drone_profiles: DroneProfile[];
  };
};
