import React, { useEffect, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/joy';
import List from '@mui/joy/List';
import { IconKey, navItems } from './navItems';
import SideNavigationItem from './SideNavigationItem';
import SideNavHeaderItem from './SideNavHeaderItem';
import { ReactComponent as HomeIcon } from '../../../icons/home.svg';
import { ReactComponent as DroneIcon } from '../../../icons/drones.svg';
import { ReactComponent as CampaignIcon } from '../../../icons/campaigns.svg';
import { ReactComponent as UserIcon } from '../../../icons/users.svg';
import { ReactComponent as PreferencesIcon } from '../../../icons/settings.svg';
import { useTranslation } from 'react-i18next';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { PiPlusSquareThin } from 'react-icons/pi';
import { CiLogout } from 'react-icons/ci';
import { CompanyService } from '../../../services/CompanyService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { organizationStore } from '../../../stores/OrganizationStore';
import { SiflyLogo } from '../../../general-utilities/SiflyLogo';

export const Navigation2 = observer(() => {
  const theme = useTheme();
  const { t: translate } = useTranslation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const logo = SiflyLogo();

  function getFillColor() {
    return theme.palette.text.tertiary;
  }

  const icons: Record<IconKey, JSX.Element> = {
    home: <HomeIcon fill={getFillColor()} />,
    drone: <DroneIcon fill={getFillColor()} />,
    droneModel: <DroneIcon fill={getFillColor()} />,
    campaign: <CampaignIcon fill={getFillColor()} />,
    user: <UserIcon fill={getFillColor()} />,
    organization: <PreferencesIcon fill={getFillColor()} />,
    preferences: <UserIcon fill={getFillColor()} />,
  };

  useEffect(() => {
    const fetchUserOrganizations = async () => {
      const userData = localStorage.getItem('user');
      if (userData) {
        const user = JSON.parse(userData);
        try {
          const response = await CompanyService.getUserOrganizations(user.id);
          const organizationsData = response?.organization_data || [];
          organizationStore.setOrganizations(organizationsData);
          organizationStore.loadSelectedOrg();

          if (!organizationStore.selectedOrg && organizationsData.length > 0) {
            const firstOrgId = organizationsData[0].id;
            organizationStore.setSelectedOrg(firstOrgId);
          }
        } catch {
          toast.error(t('navigation.errors.fetch_organization'));
        }
      }
    };

    fetchUserOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getSvgIcon = (icon: IconKey) => {
    return icons[icon];
  };

  const getNavItems = () => {
    return navItems.map((navItem) => {
      const itemTitle = translate(navItem.l10nKey);

      if (navItem.type === 'link') {
        const icon = getSvgIcon(navItem.icon);
        return <SideNavigationItem key={navItem.l10nKey} icon={icon} title={itemTitle} toLink={navItem.to} />;
      }
      if (navItem.type === 'subheader') {
        return <SideNavHeaderItem key={navItem.l10nKey} title={itemTitle} />;
      }
      return null;
    });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleOrgSelect = (orgId: number) => {
    organizationStore.setSelectedOrg(orgId);
    organizationStore.persistSelectedOrg();
    setIsDropdownOpen(false);
  };

  return (
    <Box
      sx={{
        padding: { xs: '24px', sm: '4px' },
        minHeight: '100%',
        backgroundColor: 'background.surface',
        position: 'relative',
      }}
    >
      <div
        onClick={toggleDropdown}
        className="flex justify-between items-center py-3 cursor-pointer rounded-lg w-full"
        tabIndex={0}
        role="button"
      >
        <span className="flex items-center justify-center text-base font-medium">
          <img className="h-8 w-8 mr-3" src="/images/logos/OrganizationLogo.png" alt="Organization Logo" />
          {organizationStore.organizations.find((org) => org.id === organizationStore.selectedOrg)?.name ||
            'Select an Organization'}
        </span>
        <span>{isDropdownOpen ? <IoIosArrowUp size={20} /> : <IoIosArrowDown size={20} />}</span>
      </div>

      {isDropdownOpen && (
        <Box
          ref={dropdownRef}
          className="absolute sm:left-40 w-4/5 sm:w-full py-2 rounded-lg z-10 shadow-md"
          sx={{ backgroundColor: 'background.body' }}
        >
          <Box
            sx={{
              borderBottomWidth: '1px',
              borderColor: 'neutral.200',
              maxHeight: '10rem',
              overflow: 'scroll',
            }}
          >
            {organizationStore.organizations.map((org) => (
              <button
                key={org.id}
                onClick={() => handleOrgSelect(org.id)}
                className="cursor-pointer flex items-center w-full py-2 px-2"
                style={{
                  background: organizationStore.selectedOrg === org.id ? theme.palette.neutral[700] : '',
                  color: organizationStore.selectedOrg === org.id ? theme.palette.neutral[100] : '',
                }}
              >
                <img className="h-8 w-8 mr-3" src="images/logos/OrganizationLogo.png" alt="Organization Logo" />
                <span className="text-base font-normal">{org.name}</span>
              </button>
            ))}
          </Box>

          <button
            onClick={() => navigate('/create-organization')}
            className="cursor-pointer flex items-center w-full py-2 px-2"
          >
            <PiPlusSquareThin size={20} className="mr-3" />
            <span className="text-base font-normal">Create new Organization</span>
          </button>

          <button onClick={() => navigate('/logout')} className="cursor-pointer flex items-center w-full py-2 px-2">
            <CiLogout size={20} className="transform -scale-x-100 mr-3" />
            <span className="text-base font-normal">Log out</span>
          </button>
        </Box>
      )}

      <List
        size="sm"
        sx={{
          '--ListItem-radius': '8px',
          '--List-gap': '8px',
          '--listItemDecorator-size': '24px',
        }}
      >
        {getNavItems()}
      </List>
      <Box sx={{ width: 100, height: 40, position: 'absolute', bottom: '0px' }}>
        <img src={logo} alt="Sifly Logo" />
      </Box>
    </Box>
  );
});
