export type IconKey = 'home' | 'drone' | 'droneModel' | 'campaign' | 'user' | 'organization' | 'preferences';

export interface NavigationLink {
  type: 'link';
  l10nKey: string;
  icon: IconKey;
  to: string;
}

export interface NavigationSubheader {
  type: 'subheader';
  l10nKey: string;
}

type NavigationItem = NavigationLink | NavigationSubheader;

export const navItems: NavigationItem[] = [
  {
    type: 'link',
    l10nKey: 'navigation.dashboard',
    icon: 'home',
    to: '/dashboard',
  },
  {
    type: 'link',
    l10nKey: 'navigation.drones',
    icon: 'drone',
    to: '/drones',
  },
  {
    type: 'link',
    l10nKey: 'navigation.campaigns',
    icon: 'campaign',
    to: '/campaigns',
  },
  {
    type: 'link',
    l10nKey: 'navigation.users_permissions',
    icon: 'user',
    to: '/user-permissions',
  },
  {
    type: 'subheader',
    l10nKey: 'navigation.settings_header',
  },

  {
    type: 'link',
    l10nKey: 'navigation.organization_settings',
    icon: 'organization',
    to: '/organization-settings',
  },
  {
    type: 'link',
    l10nKey: 'navigation.account_preferences',
    icon: 'preferences',
    to: '/account-preferences',
  },
  {
    type: 'subheader',
    l10nKey: 'navigation.admin_tools',
  },
  {
    type: 'link',
    l10nKey: 'navigation.drone_models',
    icon: 'drone',
    to: '/drone-models',
  },
  {
    type: 'link',
    l10nKey: 'navigation.drone_profiles',
    icon: 'drone',
    to: '/drone-profiles',
  },
];
