import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Typography, Input } from '@mui/joy';
import { Campaign } from '../types/Campaign';
import { CampaignService } from '../services/CampaignService';
import TableHeader from './TableHeader';
import GeneralModal from './GeneralModal';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CampaignSchema from '../schema/campaignSchema';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextInputField } from '../components/TextInputField';
import { SelectField } from '../components/SelectField';
import { campaignOptions } from '../constants/CampaignOptions';
import { formatTimestamp } from '../general-utilities/time-util';
import { CircularProgress } from '@mui/material';
import { organizationStore } from '../stores/OrganizationStore';

interface CampaignFormInput {
  name: string;
  description: string;
  status: number;
}

const CampaignList = observer(() => {
  const { t } = useTranslation();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { selectedOrg } = organizationStore;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CampaignFormInput>({
    resolver: yupResolver(CampaignSchema(t)),
    defaultValues: {
      name: '',
      description: '',
      status: 0,
    },
  });

  const fetchCampaigns = async () => {
    setLoading(true);
    setError(false);
    try {
      const data = await CampaignService.getCampaigns();
      const org_data = data.filter((campaign) => campaign.organization === selectedOrg);
      setCampaigns(org_data);
    } catch {
      setError(true);
      toast.error(t('campaigns.loading_error'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrg]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAddCampaign = async (data: CampaignFormInput) => {
    if (selectedOrg === null) {
      toast.error(t('general_errors.no_organization_selected'));
      return;
    }
    try {
      const response = await CampaignService.createCampaign({
        ...data,
        organization: selectedOrg!,
      });
      setCampaigns([...campaigns, response]);
      toast.success(t('campaigns.messages.campaign_created'));
      reset();
      setIsModalOpen(false);
    } catch {
      toast.error(t('campaigns.errors.failed_to_create_campaign'));
    }
  };

  const toggleCampaignStatus = async (campaignId: string, currentStatus: number) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    const statusLabel = newStatus === 1 ? 'Active' : 'Inactive';
    try {
      const updatedCampaign = await CampaignService.updateCampaignStatus(campaignId, newStatus);
      setCampaigns(
        campaigns.map((campaign) =>
          campaign.id === campaignId
            ? {
                ...campaign,
                status: updatedCampaign.status,
                start_time: updatedCampaign.start_time,
                end_time: updatedCampaign.end_time,
              }
            : campaign,
        ),
      );
      toast.success(t('campaigns.campaign_status_updated', { statusLabel }));
    } catch {
      toast.error(t('campaigns.errors.campaign_status_update_failed'));
    }
  };

  const filteredCampaigns = campaigns.filter((campaign) =>
    campaign.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <>
      <TableHeader title="Campaigns">
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, ml: 2 }}>
          <Input
            placeholder="Search"
            startDecorator={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              '--Input-minHeight': '2.5rem',
              maxWidth: '25rem',
              width: '100%',
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', gap: 1 }}>
          <Button color="neutral" onClick={handleOpenModal}>
            {t('campaigns.add_campaign')}
          </Button>
        </Box>
      </TableHeader>

      <Box sx={{ padding: '20px', fontFamily: "'Arial', sans-serif" }}>
        {loading ? (
          <Box sx={{ textAlign: 'center', padding: '50px' }}>
            <CircularProgress />
            <Typography level="body-md" marginTop="15px">
              {t('loading')}
            </Typography>
          </Box>
        ) : error ? (
          <Box sx={{ textAlign: 'center', padding: '50px' }}>
            <Typography color="danger" level="body-md">
              {t('campaigns.loading_failed')}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'grid',
              gap: '20px',
              gridTemplateColumns: 'repeat(4, 1fr)',
            }}
          >
            {filteredCampaigns.map((campaign) => (
              <Box
                key={campaign.id}
                sx={{ boxShadow: 'lg', borderRadius: 'md', padding: 2, minHeight: 150, maxWidth: 340 }}
              >
                <Typography level="h4" marginBottom="10px">
                  {campaign.name}
                </Typography>
                <Typography level="body-md" marginBottom="15px">
                  {campaign.description}
                </Typography>
                <Typography level="body-md" marginBottom="15px">
                  Start Time: {formatTimestamp(campaign.start_time)}
                </Typography>
                <Typography level="body-md" marginBottom="15px">
                  End Time:{' '}
                  {campaign.end_time === null
                    ? 'Not ended Yet'
                    : formatTimestamp(campaign.end_time ? campaign.end_time : '')}
                </Typography>
                <Button
                  variant="solid"
                  fullWidth
                  onClick={() => toggleCampaignStatus(campaign.id, campaign.status)}
                  color={campaign.status === 1 ? 'danger' : 'success'}
                >
                  {campaign.status === 1 ? 'Deactivate' : 'Activate'}
                </Button>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      <GeneralModal
        open={isModalOpen}
        title={t('campaigns.add_title')}
        content={
          <form id="campaign-form" onSubmit={handleSubmit(handleAddCampaign)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: 550 }}>
              <Box>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextInputField
                      label="Campaign Name"
                      placeholder="Enter Campaign Name"
                      {...field}
                      error={errors.name?.message}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextInputField
                      label="Campaign Description"
                      placeholder="Enter Description"
                      {...field}
                      error={errors.description?.message}
                    />
                  )}
                />
              </Box>
              <Box>
                <Typography level="body-md">{t('campaigns.status_label')}</Typography>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <SelectField
                      label=""
                      placeholder="Select Campaign Status"
                      {...field}
                      options={campaignOptions}
                      error={errors.status?.message}
                    />
                  )}
                />
              </Box>
            </Box>
          </form>
        }
        actions={
          <>
            <Button variant="plain" color="neutral" onClick={handleCloseModal}>
              {t('general_button_labels.cancel')}
            </Button>
            <Button type="submit" form="campaign-form" variant="solid" color="neutral">
              {t('general_button_labels.save')}
            </Button>
          </>
        }
        onClose={handleCloseModal}
      />
    </>
  );
});

export default CampaignList;
