export const formatTimestamp = (timestamp: string): string => {
  const date = new Date(timestamp);

  const userLocale = navigator.language || 'en-US';

  return date.toLocaleString(userLocale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  });
};
