import ResponsiveLayout from '../components/layout/ResponsiveLayout';
import CampaignList from '../components/CampaignList';

const Campaign = () => {
  return (
    <ResponsiveLayout>
      <CampaignList />
    </ResponsiveLayout>
  );
};

export default Campaign;
