import { CssVarsProvider } from '@mui/joy';
import { FC, createContext, useContext, ReactNode, useState } from 'react';
import sifly from '../themes/sifly';
import sessionStore from '../stores/SessionStore';

export type Mode = 'light' | 'dark' | 'default';

interface ThemeContextType {
  mode: Mode;
  changeTheme: (mode: Mode) => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const [mode, setMode] = useState<Mode>((sessionStore.user?.color_scheme_preference as Mode) || 'default');

  return (
    <ThemeContext.Provider value={{ mode: mode, changeTheme: setMode }}>
      <CssVarsProvider theme={sifly} disableTransitionOnChange>
        {children}
      </CssVarsProvider>
    </ThemeContext.Provider>
  );
};

export const useCustomTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useCustomTheme must be used within a ThemeProvider');
  }
  return context;
};
