import { apiClient } from './ApiService';
import { USERS_ROLE_BULK_UPDATE } from '../constants/UserRoles';

const assignRole = async (userId: number, role: string): Promise<any> => {
  try {
    return await apiClient.post<any>('/assign-role', { userId, role }, {}, true);
  } catch (error) {
    console.error('Error assigning role:', error);
    throw error;
  }
};

const bulkUpdateRoles = async (roleIds: string[], roleId: number): Promise<void> => {
  try {
    return await apiClient.put(USERS_ROLE_BULK_UPDATE, {
      ids: roleIds,
      role_id: roleId,
    });
  } catch (error) {
    console.error('Error updating user roles:', error);
    throw error;
  }
};

export const UserRoleService = { assignRole, bulkUpdateRoles };
