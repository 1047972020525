import * as Yup from 'yup';

const DroneProfileSchema = (t: (key: string) => string) =>
  Yup.object().shape({
    name: Yup.string().required(t('signup_page.errors.required')),
    eid: Yup.string().required(t('signup_page.errors.required')),
    drone_model: Yup.string().required(t('signup_page.errors.required')),
  });

export default DroneProfileSchema;
